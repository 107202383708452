import '../css/style.css';
import logoImage from '../assets/images/gotype-logo.png';

// Set the logo source
document.addEventListener('DOMContentLoaded', () => {
    const logoElements = document.querySelectorAll('.site-logo');
    logoElements.forEach(logo => {
        logo.src = logoImage;
    });
});
